function show_discount(data) {
    percent = data['percent'];
    discounted_total=  data['total'];
    watchmodelsnote = data['watchmodelsnote'];
    $('.cart__discount').show();
    $('#cart__discount__value').html(percent + ' %' +
        '<span class="watchmodels-note">' +
        watchmodelsnote + '</span>');
    $('#cart__total').html(discounted_total + ' €');
}

function hide_discount() {
    $('.cart__discount').hide();
    $('#cart__total').html($('#hidden_total').prop('value') + ' €');
}

function validate_coupon_code(code, coupon_input, cart_id) {
    $('#cart').addClass('loading');
    $.get('/coupons/validate/', {'code': code, 'cart_id': cart_id})
        .done(function(data) {
            if (data['status'] == 'valid') {
                coupon_input.removeClass('invalid').addClass('valid');
                show_discount(data);
            } else {
                coupon_input.removeClass('valid').addClass('invalid');
                if (coupon_input.prop('value') == '')
                    coupon_input.removeClass('invalid');
                hide_discount();
            }
        })
        .fail(function(data){
            // console.log('error');
        })
        .always(function() {
             $('#cart').removeClass('loading');
        });
}

/*
 *  Add change-Listener to Coupon-Input with
 *  Delay of the Validation of the Couponcode.
 */
function initCouponCode() {
    var couponTimerID = null;
    var cart_id = $('#hidden_cart_id').prop('value');
    $('.coupon').change(function() {
        $this = $(this);
        if (couponTimerID != null) {
            clearTimeout( couponTimerID );
        }
        couponTimerID = setTimeout(function() {
            couponTimerID = null;
            code = $this.prop('value').trim();
            validate_coupon_code(code, $this, cart_id);
        }, 1000);
    });
    // validate on start in case the cart is rerendert
    $coupon_input = $('#id_coupon_code');
    code = $coupon_input.prop('value');
    if (code != '') {
        validate_coupon_code(code, $coupon_input, cart_id);
    }
}
